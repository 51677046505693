import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Table } from "reactstrap";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import "../ShipmentAddressInfoPage/ShipmentAddressInfoPage.css";
import OriginationAddress from "../../models/OriginationAddress";
import { CLEAR_ORIGINATION_ZIP_INFO, VALIDATE_ORIGINATION_ZIP } from "../../redux/constants/zipLookupConstants/ZipLookup.Constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import AS400ZipLookupResponse from "../../models/AS400ZipLookupResponse";
import { iPageElements } from "../../redux/reducers/pageElementsReducer/PageElements.type";
import { SET_ORIGINATION_ADDRESS } from "../../redux/constants/shipmentAddressConstants/ShipmentAddress.Constants";

interface OriginationAddressInputProps {
    earliestPickupDate: Date | null;
    dockOpenTime: Date | null;
    dockCloseTime: Date | null;
    setEarliestPickupDate: (date: Date | null) => void;
    setDockOpenTime: (time: Date | null) => void;
    setDockCloseTime: (time: Date | null) => void;
}

export default function OriginationAddressInput({
    earliestPickupDate,
    dockOpenTime,
    dockCloseTime,
    setEarliestPickupDate,
    setDockOpenTime,
    setDockCloseTime,
}: OriginationAddressInputProps) {
    const originationAddress: OriginationAddress = useSelector((store: ReduxState) => store.OriginationAddressReducer);
    const zipCodeInfo: AS400ZipLookupResponse = useSelector((store: ReduxState) => store.OriginationZipLookupReducer);
    const pageElements: iPageElements = useSelector((store: ReduxState) => store.PageElementsReducer);

    const [originName, setOriginName] = useState(originationAddress.name || "");
    const [originStreetAddress1, setOriginStreetAddress1] = useState(originationAddress.address1 || "");
    const [originStreetAddress2, setOriginStreetAddress2] = useState(originationAddress.address2 || "");
    const [originCity, setOriginCity] = useState(originationAddress.city || "");
    const [originState, setOriginState] = useState(originationAddress.state || "");
    const [originZip, setOriginZip] = useState(originationAddress.zip || "");
    const [originComments, setOriginComments] = useState(originationAddress.comments || "");
    const [shouldCheckZip, setShouldCheckZip] = useState(false);

    const usaZipValidation = /^\d{5}$/;
    const canadaZipValidation = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;

    const styleDarkMode = pageElements.isDarkTheme ? "k-text-light" : "";

    const steps = {
        hour: 1,
        minute: 15,
    };

    const dispatch = useDispatch();

    useEffect(() => {
        function zipLookup() {
            if (usaZipValidation.test(originZip)) {
                dispatch({ type: VALIDATE_ORIGINATION_ZIP, payload: originZip });
            } else if (canadaZipValidation.test(originZip)) {
                //Allow
            } else {
                setOriginZip("");
                window.alert("Invalid zip code. Enter a valid zip code");
            }
        }

        const isCompleteCanadianZip = canadaZipValidation.test(originZip) && (originZip.length === 6 || originZip.length === 7);
        const isCompleteUsaZip = usaZipValidation.test(originZip) && originZip.length === 5;

        if (shouldCheckZip && (isCompleteUsaZip || isCompleteCanadianZip)) {
            console.log("in here");
            setShouldCheckZip(false);
            zipLookup();
        } else if (originZip.length < 5 && originZip.length > 0) {
            setShouldCheckZip(true);
        } else if (originZip.length === 0) {
            dispatch({ type: CLEAR_ORIGINATION_ZIP_INFO });
            setShouldCheckZip(true);
            setOriginCity("");
            setOriginState("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, shouldCheckZip, originZip]);

    useEffect(() => {
        const validInputs =
            originName !== "" &&
            originStreetAddress1 !== "" &&
            originZip !== "" &&
            originCity !== "" &&
            originState !== "" &&
            earliestPickupDate !== null &&
            dockOpenTime !== null &&
            dockCloseTime !== null;

        if (validInputs)
            dispatch({
                type: SET_ORIGINATION_ADDRESS,
                payload: {
                    name: originName,
                    address1: originStreetAddress1,
                    address2: originStreetAddress2,
                    city: originCity,
                    state: originState,
                    zip: originZip,
                    comments: originComments,
                    pickupWindowStart: earliestPickupDate,
                    dockOpen: dockOpenTime,
                    dockClose: dockCloseTime,
                },
            });
    }, [
        dispatch,
        originName,
        originStreetAddress1,
        originStreetAddress2,
        originCity,
        originState,
        originZip,
        originComments,
        earliestPickupDate,
        dockOpenTime,
        dockCloseTime,
    ]);

    useEffect(() => {
        if (zipCodeInfo.success === "1") {
            setOriginState(zipCodeInfo?.output?.state);
            setOriginCity(zipCodeInfo?.output?.city);
        }
    }, [zipCodeInfo, originZip]);

    return (
        <div style={{ display: "flex", width: "100%" }}>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <h2 className={styleDarkMode}>Origination Address</h2>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Form>
                                <Input
                                    className="input-styling"
                                    maxLength={128}
                                    required={true}
                                    name="originName"
                                    placeholder={originName === "" ? "Vendor Name" : originName}
                                    value={originName}
                                    onChange={(e) => setOriginName(e.target.value)}
                                    autoComplete="organization"
                                />
                                <Input
                                    className="input-styling"
                                    maxLength={128}
                                    required={true}
                                    placeholder={originStreetAddress1 === "" ? "Origination Street Address 1" : originStreetAddress1}
                                    value={originStreetAddress1}
                                    onChange={(e) => setOriginStreetAddress1(e.target.value)}
                                    autoComplete="street-address" // Use "street-address" for a street address
                                />
                                <Input
                                    className="input-styling"
                                    maxLength={128}
                                    placeholder={originStreetAddress2 === "" ? "Origination Street Address 2 (optional)" : originStreetAddress2}
                                    value={originStreetAddress2}
                                    onChange={(e) => setOriginStreetAddress2(e.target.value)}
                                    autoComplete="address-line2" // Use "address-line2" for a secondary address line
                                />
                                <Input
                                    style={{
                                        marginTop: "0.5em",
                                        width: "100%",
                                    }}
                                    maxLength={10}
                                    required={true}
                                    placeholder={originZip === "" ? "Origination Zipcode" : originZip}
                                    value={originZip}
                                    onChange={(e) => setOriginZip(e.target.value)}
                                    autoComplete="postal-code" // Use "postal-code" for a postal code or zip code
                                />
                                <Input
                                    className="input-styling"
                                    maxLength={128}
                                    required={true}
                                    placeholder={originCity === "" ? "Origination City" : originCity}
                                    value={originCity}
                                    onChange={(e) => setOriginCity(e.target.value)}
                                    autoComplete="address-level2" // Use "address-level2" for a city or locality
                                />
                                <Input
                                    className="input-styling"
                                    maxLength={2}
                                    required={true}
                                    placeholder={originState === "" ? "Origination State/Province Abbreviation" : originState}
                                    value={originState}
                                    onChange={(e) => setOriginState(e.target.value)}
                                    autoComplete="address-level1" // Use "address-level1" for a state or province abbreviation
                                />
                                <Input
                                    style={{
                                        marginTop: "0.5em",
                                        width: "100%",
                                    }}
                                    maxLength={1000}
                                    placeholder={originComments === "" ? "Notes / Additional Comments (optional)" : originComments}
                                    value={originComments}
                                    onChange={(e) => setOriginComments(e.target.value)}
                                    autoComplete="on"
                                />
                                <div>
                                    <DatePicker
                                        required={true}
                                        className="input-styling"
                                        placeholder="Earliest date shipment can be picked up? *"
                                        value={earliestPickupDate}
                                        onChange={(date) => setEarliestPickupDate(date.value)}
                                    />
                                    <TimePicker
                                        format={"hh:mm a"}
                                        steps={steps}
                                        required={true}
                                        placeholder="What time does the loading dock open? *"
                                        className="input-styling"
                                        value={dockOpenTime}
                                        onChange={(time) => setDockOpenTime(time.value)}
                                    />
                                    <TimePicker
                                        format={"hh:mm a"}
                                        steps={steps}
                                        required={true}
                                        placeholder="What time does the loading dock close? *"
                                        className="input-styling"
                                        value={dockCloseTime}
                                        onChange={(time) => setDockCloseTime(time.value)}
                                    />
                                </div>
                            </Form>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}
