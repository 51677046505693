import App from "./pages/App";
import ReactDOM from "react-dom/client";
import rootReducer from "./redux/reducers/_rootReducer/_Root.Reducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./redux/sagas/_rootSaga/Root.saga";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/es/integration/react";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";

const sagaMiddleware = createSagaMiddleware();
const middlewareList = [sagaMiddleware];

Sentry.init({
    dsn: "https://78a7ce8d99f3debca60dd38a7264a29b@o1113529.ingest.us.sentry.io/4508524885114880",
    integrations: [ new Sentry.BrowserTracing(), new Sentry.Replay() ],
    tracesSampleRate: 0.10, //  Capture 10% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const persistConfig = {
    key: "root",
    storage,
    version: 1,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
    devtools: true,
    middleware: middlewareList,
});
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Router>
                    <App />
                </Router>
            </PersistGate>
        </Provider>
    </GoogleOAuthProvider>
);
